import * as uuid from 'uuid'

const $ = (selector: string, context:  Document | Element = document): HTMLElement | null => context.querySelector(selector)
const $$ = (selector: string, context:  Document | Element = document): HTMLElement[] => Array.from(context.querySelectorAll(selector))

interface DialogOptions {
  width?: number
}

class DialogManager
 {
  private stack: Dialog[] = []

  create(title: string, options: DialogOptions = {}): Dialog {
    const dialog = new Dialog(title, options)
    this.stack.push(dialog)

    return dialog
  }
}

export const confirm = async (title: string, message: string, width: number = 500): Promise<Boolean> => {
  return new Promise((resolve, reject) => {
    const dialog = global.create(title, { width })
    dialog.element.classList.add('dialog--confirm')
    dialog.content.innerHTML = message

    dialog.addButton('Cancel', () => {
      dialog.close()
      resolve(false)
    })

    const ok = dialog.addButton('OK', () => {
      dialog.close()
      resolve(true)
    })
    ok.classList.add('primary')

    dialog.show()
  })
}

export const alert = async (message: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const dialog = global.create("Notice")
    dialog.element.classList.add('dialog--alert')
    dialog.content.innerHTML = message

    const ok = dialog.addButton('OK', () => {
      dialog.close()
      resolve()
    })
    ok.classList.add('primary')

    dialog.show()
  })
}

export const prompt = async (title: string, message: string, defaultValue: string = ''): Promise<string> => {
  return new Promise((resolve, reject) => {
    const dialog = global.create(title)
    dialog.element.classList.add('dialog--prompt')
    dialog.content.innerHTML = message

    const input = document.createElement('input')
    input.classList.add('dialog__input')
    input.setAttribute('type', 'text')
    input.setAttribute('value', defaultValue)
    dialog.content.appendChild(input)

    dialog.addButton('Cancel', () => {
      dialog.close()
      resolve('')
    })

    const ok = dialog.addButton('OK', () => {
      dialog.close()
      resolve(input.value)
    })
    ok.classList.add('primary')

    dialog.show()
  })
}

class Dialog {
  // Unique identifier for the dialog
  readonly id: string

  // The dialog's content region
  readonly content: HTMLElement

  // The dialog's title bar
  readonly titleBar: HTMLElement

  // The dialog's button bar
  readonly buttonBar: HTMLElement

  // The dialog wrapper element
  readonly element: HTMLDialogElement

  private title: string
  private modal: Boolean = true //TODO: Make this configurable later
  private isVisible: Boolean = false
  private listeners: { [key: string]: Function[] } = {}

  constructor(title: string, options: DialogOptions = {}) {
    this.id = uuid.v4()
    this.title = title

    this.element = this.buildDialog()

    this.titleBar = $('.dialog__title-bar', this.element)!
    this.content = $('.dialog__content', this.element)!
    this.buttonBar = $('.dialog__button-bar', this.element)!

    this.setupKeybindings()

    if (options.width) {
      this.element.style.width = `${options.width}px`
    }
  }

  on(event: string, callback: Function) {
    if (!this.listeners[event]) {
      this.listeners[event] = []
    }

    this.listeners[event].push(callback)
  }

  show() {
    if (this.isVisible) { return }

    document.body.appendChild(this.element)

    this.element.showModal()

    this.isVisible = true

    this.fireListeners('open')
  }

  close() {
    if (!this.isVisible) { return }

    this.element.close()

    this.isVisible = false

    this.fireListeners('close')
  }

  addButton(label: string, callback?: Function): HTMLButtonElement {
    const button = document.createElement('button')
    button.classList.add('dialog__button')
    button.textContent = label

    if (callback) {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        callback(e)
      })
    }

    this.buttonBar.appendChild(button)

    return button
  }

  private fireListeners(event: string) {
    if (!this.listeners[event]) { return }

    this.listeners[event].forEach((callback) => callback(this))
  }

  private buildDialog(): HTMLDialogElement {
    const dialog = document.createElement('dialog')
    dialog.classList.add('dialog')
    dialog.setAttribute('id', `dialog-${this.id}`)

    const titleBar = document.createElement('header')
    titleBar.classList.add('dialog__title-bar')

    const title = document.createElement('h2')
    title.classList.add('dialog__title')
    title.textContent = this.title
    title.setAttribute('id', `dialog-${this.id}-title`)
    titleBar.appendChild(title)

    const content = document.createElement('section')
    const form = document.createElement('form')
    form.classList.add('dialog__content')
    content.setAttribute('id', `dialog-${this.id}-content`)
    content.appendChild(form)

    const buttonBar = document.createElement('footer')
    buttonBar.classList.add('dialog__button-bar')
    buttonBar.setAttribute('id', `dialog-${this.id}-button-bar`)

    dialog.appendChild(titleBar)
    dialog.appendChild(content)
    dialog.appendChild(buttonBar)

    // Setup the various accessibility bits
    dialog.setAttribute('aria-labeledby', title.id)
    dialog.setAttribute('aria-describedby', content.id)
    dialog.setAttribute("role", "alertdialog")

    return dialog
  }

  private setupKeybindings() {

    this.buttonBar.addEventListener('keydown', (e) => {
      const buttons = $$('button', this.buttonBar)
      const index = buttons.indexOf(e.target as HTMLElement)

      if (e.key === 'ArrowLeft') {
        e.preventDefault()
        buttons[Math.max(0, index - 1)].focus()
      }

      if (e.key === 'ArrowRight') {
        e.preventDefault()
        buttons[Math.min(buttons.length - 1, index + 1)].focus()
      }
    })
  }
}

// TODO: Verify this does get shared across multiple files
const global = new DialogManager()
export default global
